@import '~antd/dist/antd.css';

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

html,
body {
  box-sizing: border-box;
  max-width: 100vw !important;
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-family: 'Quicksand';
}

/* helps in in-details page */
h6 {
  font-size: 1rem;
  font-weight: 700;
}

pre,
code {
  background-color: #1d3557;
  color: whitesmoke;
  padding: 0.7rem;
  border-radius: 4px;
  font-family: 'Fira Code', monospace;
}

.left {
  margin-right: auto;
}

.left img {
  width: 120px;
}

.m-tb {
  margin: 1rem 0;
}

.home-section-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.home-section-div {
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 90vw;
}

.google_login_btn {
  display: flex;
  justify-content: center;
  width: 100vw;
  margin: 1rem;
}
