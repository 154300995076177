.slider-container {
  height: calc(8vh - 4px);
  align-items: center;
  text-align: center;
}

.slider-heading {
  color: #fff;
  margin: 0;
  padding: 4px 0 0 0;
}

.slider-text {
  color: #fff;
  margin: 0;
}
