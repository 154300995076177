.layout {
  height: 100vh;
}

.site-layout .site-layout-background {
  background: #fff;
}

.header {
  height: 8vh;
}

.content {
  padding: 0 1rem;
  overflow-x: scroll;
}

.site-layout-background {
  padding: 1.2rem;
  min-height: 75vh;
}

.footer {
  text-align: center;
  height: 48px;
  align-items: center;
  background-color: #fff;
  padding: 0;
}
